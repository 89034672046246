import React from 'react'
import Layout from '../components/layout'

export default () => (
  <Layout subTitle='Contact'>
      <p>
        Message was successfully sent
      </p>
  </Layout>
)
